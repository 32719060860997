.subject-details{

   .ant-steps-item-icon .ant-steps-icon {
      svg{
        margin-bottom: 5px;
      }

  }


}


.ant-popover-content {

  .ant-btn-primary {
    //background: white;
    color:white;
    border :none;

  }
}
