$dot-width: 20px;
.attention-dot {
  display: inline-block;
  position: relative;
  //width: $dot-width;
  //height: 18px;
}

.attention-dot div {
  position: absolute;
  //border: 2px solid red;
  opacity: 1;
  border-radius: 50%;
  animation: attention-dot 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.attention-dot div:nth-child(2) {
  //animation-delay: -0.5s;
}

@keyframes attention-dot {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

/*

@keyframes attention-dot {
  0% {
    top: 0px;
    left: 0px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -$dot-width;
    left: -$dot-width;
    width: $dot-width * 2;
    height: $dot-width * 2;
    opacity: 0;
  }
}
*/
