// it seems that antd overrides this , which is why it's included in the styles.scss on body{}
//$font-family-base: 'Open Sans', 'sans-serif';
//$body-bg: red;
//$body-color: #3d3c3a; // also font color

// Headings
$headings-font-weight: 500;

// Fonts
$font-weight-light: 400;
$font-weight-normal: 400;
$font-weight-bold: 600;
$line-height-base: 1.45;

$font-size-base: 0.8rem;
$font-size-lg: $font-size-base * 1.2;
$font-size-sm: $font-size-base * 0.8;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3rem !default;

// there is some difference between development and production builds
//$paragraph-margin-bottom: 0;

/*$line-height-lg: 1.7;
$line-height-sm: 1.7;
$line-height-base: 1.7;*/
$white: #fff !default;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900,
  ),
  $grays
);

$blue: #08455f !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #ff7537 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;

$colors: ();
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'black': $black,
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800,
  ),
  $colors
);

$primary: $blue !default;
$secondary: #e7a600 !default;
$success: $green !default;
$info: #e7a600 !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'orange': $orange,
    'black': $black,
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
  ),
  $theme-colors
);

// Grid
$grid-breakpoints: (
  // Unchanged
    xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default

$sizes: map-merge(
  (
    10: 10%,
    15: 15%,
    20: 20%,
    25: 25%,
    30: 30%,
    33: 33%,
    35: 35%,
    40: 40%,
    45: 45%,
    50: 50%,
    60: 60%,
    75: 75%,
    70: 70%,
    80: 80%,
    85: 85%,
    90: 90%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);
