/**
 * Use this to show which breakpoint the css is using
 * when using Bootstrap responsive
 */
/*
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px*/

 body::before {
   content: "xs";
   position: fixed;
   top: 0;
   left: 0;
   z-index: 9999999;
   background-color: #000;
   color: #fff;
 }

 @media (min-width : 576px) {
   body::before {
     content: "sm";
   }
 }

 @media (min-width : 768px) {
   body::before {
     content: "md";
   }
 }

 @media (min-width : 992px) {
   body::before {
     content: "lg";
   }
 }

 @media (min-width : 1200px) {
   body::before {
     content: "xl";
   }
 }

div {
  //border: solid 1px #9999FF;
}
