@import './variables.scss';
@import './components.scss';

#root {
  height: 100%;
  background: #f4f4f4; // tried setting the background color in both bootstrap and ant theme file.
}

body {
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif !important;
}

.use-pointer {
  cursor: pointer;
}

.no-select {
  user-select: none;
}

.p-small{
  font-size: .8rem;
}

/*
@include media-breakpoint-down(md) {
  html {
    font-size: 0.9rem;

    p {
      font-size: 0.9rem;
    }
  }
}
*/
